<template>
  <v-container>
    <Snackbar :snackbar="snackbar" :snackbartext="snackbartext" @setflasesnackbar="snackbar = false"> </Snackbar>
    <div>
      <Tabs :tabs="empmenus" @settabvalue="settabvalue" />
      <v-data-table
        :headers="getheaders"
        :items="emps"
        :expanded.sync="expanded"
        item-key="officialDetails.empid"
        :show-expand="expandflag"
        class="elevation-1"
        :loading="notloaded"
        loading-text="Loading..."
        :search="search"
        :footer-props="{ prevIcon: 'mdi-arrow-left', nextIcon: 'mdi-arrow-right' }"
        @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
        :items-per-page="roles.itemsPerPage"
      >
        <template v-slot:[`item.documentDetails.images`]="{ item }">
          <span v-for="(image, index) in item.documentDetails.images" :key="index">
            <img :src="image.fileurl" alt="image.filename" width="40px" height="20px" />
          </span>
        </template>
        <!-- template to convert the date in to readable format -->
        <template v-slot:[`item.officialDetails.empDOJ`]="{ item }">
          {{ convertDate(item.officialDetails.empDOJ) }}
        </template>

        <template v-slot:[`item.documentDetails.passportExpiryDate`]="{ item }">
          {{ convertDate(item.documentDetails.passportExpiryDate) }}
        </template>

        <template v-slot:[`item.familyDetails.father.DOfB`]="{ item }">
          {{ convertDate(item.familyDetails.father.DOfB) }}
        </template>

        <template v-slot:[`item.familyDetails.mother.DOfB`]="{ item }">
          {{ convertDate(item.familyDetails.mother.DOfB) }}
        </template>
        <!-- end template to convert the date in to readable format  the rest of the dates are in inside the expansion slot.-->

        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>{{ Title }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              :loading="notloaded"
              loading-text="Loading..."
              single-line
              hide-details
            ></v-text-field>
            <v-dialog v-model="dialog" max-width="1000px">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <Alertmsg v-if="alertflag" :alertmsg="alertmsg" @setflasealert="alertflag = false"> </Alertmsg>
                <v-card-text>
                  <div>
                    <v-container>
                      <v-row>
                        <v-col cols="12" xs="4" sm="4" md="3" lg="3">
                          <div>
                            <h4>EMPID</h4>
                            {{ editedItem.officialDetails.empid }}
                          </div>
                        </v-col>
                        <v-col cols="12" xs="8" sm="8" md="4" lg="4">
                          <div>
                            <h4>NAME</h4>
                            {{ editedItem.officialDetails.name }}
                          </div>
                        </v-col>
                        <v-col cols="12" xs="6" sm="6" md="5" lg="5">
                          <div>
                            <h4>Email</h4>
                            {{ editedItem.officialDetails.email }}
                          </div>
                        </v-col>
                      </v-row>
                      <v-row v-if="Title == 'Emergency Details'">
                        <v-col cols="12" xs="6" sm="6" md="6" lg="6">
                          <v-text-field v-model="editedItem.emergencyDetails.contactName" label="Name"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="6" sm="6" md="4" lg="4">
                          <v-text-field
                            v-model="editedItem.emergencyDetails.contactNumber"
                            label="Number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="4" sm="4" md="2" lg="2">
                          <v-text-field
                            v-model="editedItem.emergencyDetails.contactRelation"
                            label="Relation"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="6" sm="6" md="4" lg="4">
                          <v-text-field
                            v-model="editedItem.emergencyDetails.alternateContact"
                            label="Alternate Contact"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <!-- end of Emergency Details-->
                      <v-row v-if="Title == 'Personal Details'">
                        <v-col cols="12" xs="6" sm="6" md="6" lg="6">
                          <v-text-field
                            v-model="editedItem.personalDetails.email"
                            label="Alternate Email"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="4" sm="4" md="4" lg="4">
                          <v-text-field
                            v-model="editedItem.personalDetails.mobileNumber"
                            label="Mobile Number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="2" sm="2" md="2" lg="2">
                       <!--   <v-text-field v-model="editedItem.personalDetails.empDOB" label="DOB"></v-text-field>-->

                        <DatePicker
                    :menu="menuDOB"
                    :datevalue="editedItem.personalDetails.empDOB"
                    :datelabeltext="'Birth date'"
                    @dateassinged="editedItem.personalDetails.empDOB = $event"
                  />
                        </v-col>
                        <v-col cols="12" xs="4" sm="4" md="4" lg="4">
                          <v-text-field
                            v-model="editedItem.personalDetails.alternateContact"
                            label="Alternate Contact"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="4" sm="4" md="2" lg="2">
                          <v-select
                            v-model="editedItem.personalDetails.matrialStatus"
                            :items="matrial"
                            label="Matrial Status"
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="12" xs="2" sm="2" md="2" lg="2">
                          <v-text-field v-model="editedItem.personalDetails.bloodGroup" label="Blood GR"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="2" sm="2" md="2" lg="2">
                          <v-text-field
                            v-model="editedItem.personalDetails.weddingAnniversary"
                            label="Wedding Date"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-textarea
                            filled
                            auto-grow
                            label="Current Address"
                            v-model="editedItem.personalDetails.currentAddress"
                            rows="2"
                            row-height="20"
                          ></v-textarea>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                          <v-textarea
                            filled
                            auto-grow
                            label="Permanent Address"
                            v-model="editedItem.personalDetails.permanentAddress"
                            rows="2"
                            row-height="20"
                          ></v-textarea>
                        </v-col> </v-row
                      ><!-- end of Personal Details-->
                      <v-row v-if="Title == 'Family Details'">
                        <v-col cols="12" xs="6" sm="6" md="4" lg="4">
                          <v-text-field
                            v-model="editedItem.familyDetails.father.name"
                            label="Father name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="6" sm="6" md="2" lg="2">
                         <!-- <v-text-field
                            v-model="editedItem.familyDetails.father.DOfB"
                            label="Father DOB"
                          ></v-text-field>-->

                           <DatePicker
                    :menu="fatherDOB"
                    :datevalue="editedItem.familyDetails.father.DOfB"
                    :datelabeltext="'Father DOB'"
                    @dateassinged="editedItem.familyDetails.father.DOfB = $event"
                  />
                        </v-col>
                        <v-col cols="12" xs="6" sm="6" md="4" lg="4">
                          <v-text-field
                            v-model="editedItem.familyDetails.mother.name"
                            label="Mother name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="6" sm="6" md="2" lg="2">
                        <!--  <v-text-field
                            v-model="editedItem.familyDetails.mother.DOfB"
                            label="Mother DOB"
                          ></v-text-field>-->

                           <DatePicker
                    :menu="motherDOB"
                    :datevalue="editedItem.familyDetails.mother.DOfB"
                    :datelabeltext="'Mother DOB'"
                    @dateassinged="editedItem.familyDetails.mother.DOfB = $event"
                  />
                        </v-col>
                        <v-col cols="12" xs="6" sm="6" md="4" lg="4">
                          <v-text-field
                            v-model="editedItem.familyDetails.spouse.name"
                            label="Spouse name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="6" sm="6" md="2" lg="2">
                         <!-- <v-text-field
                            v-model="editedItem.familyDetails.spouse.DOfB"
                            label="Spouse DOB"
                          ></v-text-field>-->
                           <DatePicker
                    :menu="spouseDOB"
                    :datevalue="editedItem.familyDetails.spouse.DOfB"
                    :datelabeltext="'Spouse DOB'"
                    @dateassinged="editedItem.familyDetails.spouse.DOfB = $event"
                  />
                        </v-col>
                        <v-col cols="12" xs="6" sm="6" md="3" lg="4">
                          <v-text-field
                            v-model="editedItem.familyDetails.child1.name"
                            label="Child1 name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="6" sm="6" md="2" lg="2">
                        <!--  <v-text-field
                            v-model="editedItem.familyDetails.child1.DOfB"
                            label="Child1 DOB"
                          ></v-text-field>-->

                             <DatePicker
                    :menu="child1DOB"
                    :datevalue="editedItem.familyDetails.child1.DOfB"
                    :datelabeltext="'Child1 DOB'"
                    @dateassinged="editedItem.familyDetails.child1.DOfB = $event"
                  />
                        </v-col>
                        <v-col cols="12" xs="6" sm="6" md="1" lg="1">
                          <v-text-field v-model="editedItem.familyDetails.child1.gender" label="Gender"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="6" sm="6" md="3" lg="3">
                          <v-text-field
                            v-model="editedItem.familyDetails.child2.name"
                            label="Child2 name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="6" sm="6" md="2" lg="2">
                         <!-- <v-text-field
                            v-model="editedItem.familyDetails.child2.DOfB"
                            label="Child2 DOB"
                          ></v-text-field>-->
                       

                         <DatePicker
                    :menu="child2DOB"
                    :datevalue="editedItem.familyDetails.child2.DOfB"
                    :datelabeltext="'Child2 DOB'"
                    @dateassinged="editedItem.familyDetails.child2.DOfB = $event"
                  />
                   </v-col>
                        <v-col cols="12" xs="6" sm="6" md="1" lg="1">
                          <v-text-field v-model="editedItem.familyDetails.child2.gender" label="Gender"></v-text-field>
                        </v-col>
                      </v-row>
                      <!-- end of Family Details-->
                      <v-row v-if="Title == 'Official Details'">
                        <v-col cols="12" xs="6" sm="6" md="4" lg="4">
                          <v-select
                            v-model="editedItem.officialDetails.mgrname"
                            label="Manager name"
                            :rules="charRules"
                            :items="mgrnames"
                            @input="selectedmgrnames"
                            required
                          ></v-select>
                        </v-col>
                        <v-col cols="12" xs="6" sm="6" md="4" lg="4">
                          <v-select
                            v-model="editedItem.officialDetails.mgremail"
                            label="Manager Email"
                            :rules="emailRules"
                            :items="mgremails"
                            @input="selectedmgremails"
                            required
                          ></v-select>
                        </v-col>
                        <v-col cols="12" xs="6" sm="6" md="4" lg="4">
                          <v-combobox
                            v-model="editedItem.officialDetails.clientmgrname"
                            label="Reviewer name"
                            :rules="charRules"
                            :items="reviewersnames"
                            @input="selectedreviewersnames"
                            required
                          ></v-combobox>
                        </v-col>
                        <v-col cols="12" xs="6" sm="6" md="4" lg="4">
                          <v-combobox
                            v-model="editedItem.officialDetails.clientmgremail"
                            label="Reviewer Email"
                            :rules="emailRules"
                            :items="reviewersemails"
                            @input="selectedreviewersemails"
                            required
                          ></v-combobox>
                        </v-col>
                        <v-col cols="12" xs="6" sm="6" md="2" lg="2">
                          <DatePicker
                            :menu="menuDOJ"
                            :datevalue="editedItem.officialDetails.empDOJ"
                            :datelabeltext="'Joining Date'"
                            @dateassinged="editedItem.officialDetails.empDOJ = $event"
                          />
                        </v-col>
                        <v-col cols="12" xs="6" sm="6" md="2" lg="2">
                          <v-text-field
                            v-model="editedItem.officialDetails.deptName"
                            label="Department Name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="6" sm="6" md="2" lg="2">
                          <v-text-field v-model="editedItem.officialDetails.grade" label="Grade"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="6" sm="6" md="2" lg="2">
                          <v-text-field
                            v-model="editedItem.officialDetails.designation"
                            label="Designation"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="6" sm="6" md="2" lg="2">
                          <v-text-field v-model="editedItem.officialDetails.client" label="Client Name"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="6" sm="6" md="2" lg="2">
                          <v-text-field v-model="editedItem.officialDetails.onsite" label="Onsite"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="6" sm="6" md="2" lg="2">
                          <v-text-field
                            v-model="editedItem.officialDetails.onsitecountry"
                            label="Onsite Country"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" xs="6" sm="6" md="2" lg="2">
                          <DatePicker
                            :menu="menuDOE"
                            :datevalue="editedItem.officialDetails.empDOE"
                            :datelabeltext="'Exit date'"
                            @dateassinged="editedItem.officialDetails.empDOE = $event"
                          />
                        </v-col>
                        <v-col cols="12" xs="6" sm="6" md="2" lg="2">
                          <DatePicker
                            :menu="menuDOR"
                            :datevalue="editedItem.officialDetails.empDOR"
                            :datelabeltext="'Resgination Date'"
                            @dateassinged="editedItem.officialDetails.empDOR = $event"
                          />
                        </v-col>
                      </v-row>
                      <!-- end of Official-->
                      <v-row v-if="Title == 'Document Details'">
                        <v-col cols="12" xs="6" sm="6" md="3" lg="3">
                          <v-text-field
                            v-model="editedItem.documentDetails.addharNumber"
                            label="Aadhar Number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="6" sm="6" md="2" lg="2">
                          <v-text-field
                            v-model="editedItem.documentDetails.panNumber"
                            label="Pan Number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="6" sm="6" md="2" lg="2">
                          <v-text-field v-model="editedItem.documentDetails.pfUAN" label="PF-UANumber"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="6" sm="6" md="2" lg="2">
                          <v-text-field
                            v-model="editedItem.documentDetails.passportNumber"
                            label="Passport Number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="6" sm="6" md="2" lg="2">
                          <v-text-field
                            v-model="editedItem.documentDetails.passportExpiryDate"
                            label="Passport Expiry Date"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                          <div>
                            <Upload
                              ref="Documents"
                              :attachments="editedItem.documentDetails.images"
                              :storagepath="storagePath"
                              :uploadname="`Documents`"
                              :disabled="false"
                              @addAttachment="addAttachment"
                              @removeAttachment="removeAttachment"
                            />
                          </div>
                        </v-col>
                      </v-row>
                      <!-- end of Document Details-->
                    </v-container>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                  <v-btn color="blue darken-1" v-if="!spinner" text @click="saveitem">Save</v-btn>
                  <Spinner v-if="spinner" />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template
          v-if="Title != 'Emergency Details' && Title != 'All Details'"
          v-slot:expanded-item="{ headers, item }"
        >
          <td :colspan="headers.length">
            <v-row v-if="Title == 'Official Details'">
              <v-col cols="12" xs="4" sm="4" md="2" lg="2">
                <h4>Department</h4>
                {{ item.officialDetails.deptName }}
              </v-col>
              <v-col cols="12" xs="4" sm="4" md="4" lg="3">
                <h4>Manager Email</h4>
                {{ item.officialDetails.mgremail }}
              </v-col>
              <v-col cols="12" xs="4" sm="4" md="2" lg="2">
                <h4>Client Manager Name</h4>
                {{ item.officialDetails.clientmgrname }}
              </v-col>

              <v-col cols="12" xs="4" sm="4" md="4" lg="4">
                <h4>Client Manager Email</h4>
                {{ item.officialDetails.clientmgremail }}
              </v-col>
              <v-col cols="12" xs="4" sm="4" md="2" lg="2">
                <h4>Designation</h4>
                {{ item.officialDetails.designation }}
              </v-col>
              <v-col cols="12" xs="2" sm="2" md="1" lg="1">
                <h4>Grade</h4>
                {{ item.officialDetails.grade }}
              </v-col>
              <v-col cols="12" xs="4" sm="4" md="2" lg="2">
                <h4>Client</h4>
                {{ item.officialDetails.client }}
              </v-col>
              <v-col cols="12" xs="4" sm="4" md="1" lg="1">
                <h4>Onsite</h4>
                {{ item.officialDetails.onsiteyesno }}
              </v-col>
              <v-col v-if="item.officialDetails.onsitecountry" cols="12" xs="4" sm="4" md="2" lg="2">
                <h4>Onsite Country</h4>
                {{ item.officialDetails.onsitecountry }}
              </v-col>
              <v-col v-if="item.officialDetails.empDOR" cols="12" xs="4" sm="4" md="2" lg="2">
                <h4>DOR</h4>
                {{ convertDate(item.officialDetails.empDOR) }}
              </v-col>
              <v-col v-if="item.officialDetails.empDOE" cols="12" xs="4" sm="4" md="2" lg="2">
                <h4>DOE</h4>
                {{ convertDate(item.officialDetails.empDOE) }}
              </v-col>
            </v-row>

            <v-row v-if="Title == 'Family Details'">
              <v-col cols="12" xs="4" sm="4" md="2" lg="2">
                <h4>Spouse Name</h4>
                {{ item.familyDetails.spouse.name }}
              </v-col>
              <v-col cols="12" xs="4" sm="4" md="2" lg="2">
                <h4>Spouse DOB</h4>
                {{ convertDate(item.familyDetails.spouse.DOfB) }}
              </v-col>

              <v-col v-if="item.familyDetails.child1.name" cols="12" xs="2" sm="2" md="2" lg="2">
                <h4>Child1 Name</h4>
                {{ item.familyDetails.child1.name }}
              </v-col>
              <v-col v-if="item.familyDetails.child1.name" cols="12" xs="4" sm="4" md="2" lg="2">
                <h4>Child1 DOB</h4>
                {{ convertDate(item.familyDetails.child1.DOfB) }}
              </v-col>
              <v-col v-if="item.familyDetails.child1.name" cols="12" xs="4" sm="4" md="1" lg="1">
                <h4>Gender</h4>
                {{ item.familyDetails.child1.gender }}
              </v-col>

              <v-col v-if="item.familyDetails.child2.name" cols="12" xs="2" sm="2" md="2" lg="2">
                <h4>Child2 Name</h4>
                {{ item.familyDetails.child2.name }}
              </v-col>
              <v-col v-if="item.familyDetails.child1.name" cols="12" xs="4" sm="4" md="2" lg="2">
                <h4>Child2 DOB</h4>
                {{ convertDate(item.familyDetails.child2.DOfB) }}
              </v-col>
              <v-col v-if="item.familyDetails.child1.name" cols="12" xs="4" sm="4" md="1" lg="1">
                <h4>Gender</h4>
                {{ item.familyDetails.child2.gender }}
              </v-col>
            </v-row>

            <v-row v-if="Title == 'Document Details'">
              <h4>Documents</h4>

              <Attachment
                v-for="(file, index) in item.documentDetails.images"
                :key="file.filename"
                :file="file"
                :index="index"
                :close="false"
                :color="'green'"
              />
            </v-row>

            <v-row v-if="Title == 'Personal Details'">
              <v-col cols="12" xs="4" sm="4" md="2" lg="2">
                <h4>DOB</h4>
                {{ convertDate(item.personalDetails.empDOB) }}
              </v-col>
              <v-col cols="12" xs="4" sm="4" md="2" lg="2">
                <h4>Alternate Contact</h4>
                {{ item.personalDetails.alternateContact }}
              </v-col>

              <v-col cols="12" xs="2" sm="2" md="2" lg="2">
                <h4>Matrial Status</h4>
                {{ item.personalDetails.matrialStatus }}
              </v-col>
              <v-col cols="12" xs="4" sm="4" md="2" lg="2">
                <h4>Blood Group</h4>
                {{ item.personalDetails.bloodGroup }}
              </v-col>

              <v-col v-if="item.personalDetails.weddingAnniversary" cols="12" xs="4" sm="4" md="2" lg="2">
                <h4>Wedding Anniversary</h4>
                {{ convertDate(item.personalDetails.weddingAnniversary) }}
              </v-col>

              <v-col v-if="item.personalDetails.currentAddress" cols="12" xs="12" sm="12" md="6" lg="6">
                <h4>Current Address</h4>
                {{ item.personalDetails.currentAddress }}
              </v-col>

              <v-col v-if="item.personalDetails.permanentAddress" cols="12" xs="12" sm="12" md="6" lg="6">
                <h4>Permanent Address</h4>
                {{ item.personalDetails.permanentAddress }}
              </v-col>
            </v-row>
          </td>
        </template>
        <template v-if="roles.role.hradmin == true" v-slot:[`item.actions`]="{ item }">
          <div>
            <v-icon small class="mr-2" @click.stop="editItem(item)"> mdi-pencil </v-icon>
            <v-icon v-if="Title == 'All Details'" small @click.stop="deleteItem(item)"> mdi-delete </v-icon>
          </div>
        </template>
        <template v-slot:no-data>
          <div><h2 class="blue">No data Found</h2></div>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>
<script>
import { empRef, configgenRef, leavesummariesRef, reviewersRef } from "@/fb.js"
import { formMixin } from "@/mixins/formMixin.js"
import { convertAll } from "@/mixins/convertall.js"
import Upload from "@/components/common/Upload.vue"
import Attachment from "@/components/common/Attachment.vue"
import { mapState } from "vuex"
import Snackbar from "@/components/common/snackbar.vue"
import Alertmsg from "@/components/common/Alertmsg.vue"
import DatePicker from "@/components/common/Datepicker.vue"
import Spinner from "@/components/common/spinner.vue"
import Tabs from "@/components/common/Tabs.vue"

import { collectionsOperation } from "@/mixins/collectionsOperations.js"
export default {
  name: "HRdetails",
  //  props:['roles'],
  mixins: [formMixin, convertAll, collectionsOperation],
  components: { Upload, Attachment, Snackbar, Alertmsg, DatePicker, Spinner, Tabs },
  data: () => ({
    dialog: false,
    menupd: false,
    menuied: false,
    menuDOB:false,
    fatherDOB:false,
    motherDOB:false,
    spouseDOB:false,
    child1DOB:false,
    child2DOB:false,
    menuDOJ: false,
    menuDOE: false,
    menuDOR: false,
    search: "",
    singleExpand: false,
    expanded: [],
    expandflag: "",

    Title: "",
    headers: [],
    date: null,
    mgrnames: [],
    mgremails: [],
    managers: [],
    reviewers: [],
    reviewersnames: [],
    reviewersemails: [],
    gender: ["Female", "Male"],
    matrial: ["Single", "Married"],

    charRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length >= 4) || "Name should be atleast 4 characters",
      (v) => /^[A-Za-z\s]+$/.test(v) || "Only Alphabets and Spaces allowed",
    ],
    emailRules: [(v) => !!v || "E-mail is required", (v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],

    empmenus: [
      "Official Details",
      "Personal Details",
      "Document Details",
      "Emergency Details",
      "Family Details",
      "All Details",
    ],

    personalheaders: [
      {
        text: "EMPID",
        align: "start",
        sortable: true,
        value: "officialDetails.empid",
      },
      { text: "Name", value: "officialDetails.name" },
      { text: "Email", value: "officialDetails.email" },
      { text: "Full Name", value: "personalDetails.fullName" },
      { text: "Alternate Email", value: "personalDetails.email" },
      { text: "Mobile Number", value: "personalDetails.mobileNumber" },
      { text: "Gender", value: "personalDetails.gender" },
      { text: "DOB", value: "personalDetails.empDOB", align: " d-none" },
      { text: "Alternate Contact", value: "personalDetails.alternateContact", align: " d-none" },
      { text: "Matrial Status", value: "personalDetails.matrialStatus", align: " d-none" },
      { text: "Blood Group", value: "personalDetails.bloodGroup", align: " d-none" },
      { text: "Current Address", value: "personalDetails.currentAddress", align: " d-none" },
      { text: "Permanent Address", value: "personalDetails.permanentAddress", align: " d-none" },
      { text: "WeddingAnniversary", value: "personalDetails.weddingAnniversary", align: " d-none" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    documentheaders: [
      {
        text: "EMPID",
        align: "start",
        sortable: true,
        value: "officialDetails.empid",
      },
      { text: "Name", value: "officialDetails.name" },
      { text: "Email", value: "officialDetails.email" },
      { text: "Aadhar Number", value: "documentDetails.addharNumber" },
      { text: "Pan Number", value: "documentDetails.panNumber" },
      { text: "PF Number", value: "documentDetails.pfUAN" },
      { text: "Passport Number", value: "documentDetails.passportNumber" },
      { text: "Passport Expiry", value: "documentDetails.passportExpiryDate" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    emergencyheaders: [
      {
        text: "EMPID",
        align: "start",
        sortable: true,
        value: "officialDetails.empid",
      },
      { text: "Name", value: "officialDetails.name" },
      { text: "Contact Name", value: "emergencyDetails.contactName" },
      { text: "Contact Number", value: "emergencyDetails.contactNumber" },
      { text: "Relation", value: "emergencyDetails.contactRelation" },
      { text: "Alternate Number", value: "emergencyDetails.alternateContact" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    familyheaders: [
      {
        text: "EMPID",
        align: "start",
        sortable: true,
        value: "officialDetails.empid",
      },
      { text: "Name", value: "officialDetails.name" },
      { text: "Email", value: "officialDetails.email" },
      { text: "Father Name", value: "familyDetails.father.name" },
      { text: "Father DOB", value: "familyDetails.father.DOfB" },
      { text: "Mother Name", value: "familyDetails.mother.name" },
      { text: "Mother DOB", value: "familyDetails.mother.DOfB" },
      { text: "Spouse Name", value: "familyDetails.spouse.name", align: " d-none" },
      { text: "Spouse DOB", value: "familyDetails.spouse.DOfB", align: " d-none" },
      { text: "Child1 Name", value: "familyDetails.child1.name", align: " d-none" },
      { text: "child1 DOB", value: "familyDetails.child1.DOfB", align: " d-none" },
      { text: "Gender", value: "familyDetails.child1.gender", align: " d-none" },
      { text: "Child2 Name", value: "familyDetails.child2.name", align: " d-none" },
      { text: "child2 DOB", value: "familyDetails.child2.DOfB", align: " d-none" },
      { text: "Gender", value: "familyDetails.child2.gender", align: " d-none" },

      { text: "Actions", value: "actions", sortable: false },
    ],

    officialheaders: [
      {
        text: "EMPID",
        align: "start",
        sortable: true,
        value: "officialDetails.empid",
      },
      { text: "Name", value: "officialDetails.name" },
      { text: "Email", value: "officialDetails.email" },
      { text: "Manager Name", value: "officialDetails.mgrname" },
      { text: "DOJ", value: "officialDetails.empDOJ" },
      { text: "Manager Email", value: "officialDetails.mgremail", align: " d-none" },
      { text: "Client Manager Name", value: "officialDetails.clientmgrname", align: " d-none" },
      { text: "Clinet Manager Email", value: "officialDetails.clientmgremail", align: " d-none" },
      { text: "Department", value: "officialDetails.deptName", align: " d-none" },
      { text: "Designation", value: "officialDetails.designation", align: " d-none" },
      { text: "Grade", value: "officialDetails.grade", align: " d-none" },
      { text: "Client", value: "officialDetails.client", align: " d-none" },
      { text: "Onsite", value: "officialDetails.onsiteyesno", align: " d-none" },
      { text: "onsitecountry", value: "officialDetails.onsitecountry", align: " d-none" },
      { text: "DOR", value: "officialDetails.empDOR", align: " d-none" },
      { text: "DOE", value: "officialDetails.empDOE", align: " d-none" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    allDetheaders: [
      {
        text: "EMPID",
        align: "start",
        sortable: true,
        value: "officialDetails.empid",
      },
      { text: "Name", value: "officialDetails.name" },
      { text: "Email", value: "officialDetails.email" },
      { text: "Manager Name", value: "officialDetails.mgrname" },

      { text: "Manager Email", value: "officialDetails.mgremail" },
      { text: "DOJ", value: "officialDetails.empDOJ" },
      { text: "DOR", value: "officialDetails.empDOR" },
      { text: "DOE", value: "officialDetails.empDOE" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    emps: [],
    editedIndex: -1,
    editedItem: {
      officialDetails: {
        empid: "",
        name: "",
        email: "",
        mgrname: "",
        mgremail: "",
        mgrid: "",
        clientmgrname: "",
        clientmgremail: "",
        clientmgrid: "",
        empDOJ: "",
        empDOE: "",
        deptName: "",
        grade: "",
        designation: "",
        client: "",
        onsite: "",
        onsitecountry: "",
        empDOR: "",
      },
      familyDetails: {
        father: { name: "", DOfB: "" },
        mother: { name: "", DOfB: "" },
        spouse: { name: "", DOfB: "" },
        child1: { name: "", DOfB: "", gender: "" },
        child2: { name: "", DOfB: "", gender: "" },
      },
      emergencyDetails: {},
      personalDetails: {},
      documentDetails: {
        addharNumber: null,
        pfUAN: null,
        panNumber: null,
        passportNumber: null,
        passportExpiryDate: null,
        images: [{ filename: "", filenameurl: "" }],
      },
    },
    defaultItem: {},

    notloaded: true,
    backmenu: "hradmin",
    snackbar: false,
    alertflag: false,
    snackbartext: "",
    alertmsg: "",
    roles: { role: { hradmin: "" } },
    spinner: false,
  }), // end of data
  async created() {
    this.roles = await this.$store.state.userProfile
    console.log("isnide hrdetails view creatd", this.roles)
    if (this.roles) {
      if (this.roles.role.hradmin || this.roles.role.readuser) {
        this.defaultItem = Object.assign({}, this.editedItem)
        this.domain = this.roles.domain
        this.Title = "Official Details" // set default to document details once this page laoded.
        this.initialize()
      } else {
        alert("Not authorized to access this page , kindly contact support")
        var lastmenu = this.roles.menufrom
        this.$router.replace({ name: lastmenu })
      }
    } else {
      alert("Not authorized to access this page , kindly contact support")
      this.$router.replace({ name: "/login" })
    }
  }, // end of created
  mounted() {
    empRef.where("domain", "==", this.currentDomain).onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
      
          if(!this.notloaded)
          this.updaterealtimedata(this.emps, change)
      
      }) // end of for each
    }) // end of snapshot
  }, // end of mounted

  computed: {
    ...mapState({
      currentMenu: (state) => state.currentmenu,
      currentDomain: state=> state.userProfile.domain
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Add Details" : "Edit Details"
    },
    storagePath() {
      var empdocstoreagepath = "empdocs/" + this.domain + "_" + this.editedItem.officialDetails.empid + "_"
      return empdocstoreagepath
    },

    getheaders: function () {
      var headersarray = []
      if (this.Title == "Document Details") {
        headersarray = this.documentheaders
      } else if (this.Title == "Personal Details") {
        headersarray = this.personalheaders
      } else if (this.Title == "Emergency Details") {
        headersarray = this.emergencyheaders
      } else if (this.Title == "Family Details") {
        headersarray = this.familyheaders
      } else if (this.Title == "Official Details") {
        headersarray = this.officialheaders
      } else if (this.Title == "All Details") {
        headersarray = this.allDetheaders
      }
      return headersarray
    }, // end of getheaders
  }, //end of computed 
  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  methods: {
    settabvalue(value) {
      console.log("inside settabvalue", value)
      this.Title = value
    },
    dateassinged(option, value) {
      if (option == "Joining Date") this.editedItem.officialDetails.empDOJ = value
      else if (option == "Exit Date") this.editedItem.officialDetails.empDOE = value
      else if (option == "Resgination Date") this.editedItem.officialDetails.empDOR = value
    },

    selectedmgrnames() {
      var tmpselctedarray = this.managers.filter((obj) => obj.name == this.editedItem.officialDetails.mgrname)
      if (tmpselctedarray.length > 0) {
        this.assignclinetmanagerdetails(tmpselctedarray)
      } else {
        this.assignclinetmanagerdetailsnull()
      }
    },

    selectedmgremails() {
      var tmpselctedarray = this.managers.filter((obj) => obj.email == this.editedItem.officialDetails.mgremail)
      if (tmpselctedarray.length > 0) {
        this.assignclinetmanagerdetails(tmpselctedarray)
      } else {
        this.assignclinetmanagerdetailsnull()
      }
    },
    assignclinetmanagerdetails(selctedarray) {
      this.editedItem.officialDetails.mgrname = selctedarray[0].name
      this.editedItem.officialDetails.mgrid = selctedarray[0].id
      this.editedItem.officialDetails.mgremail = selctedarray[0].email

      this.editedItem.officialDetails.clientmgrname = this.editedItem.officialDetails.mgrname
      this.editedItem.officialDetails.clientmgremail = this.editedItem.officialDetails.mgremail
      this.editedItem.officialDetails.clientmgrid = this.editedItem.officialDetails.mgrid
    },
    assignclinetmanagerdetailsnull() {
      this.editedItem.officialDetails.clientmgrname = ""
      this.editedItem.officialDetails.clientmgremail = ""
      this.editedItem.officialDetails.clientmgrid = ""
    },
    selectedreviewersnames() {
      var tmpselctedarray = this.reviewers.filter((obj) => obj.name == this.editedItem.officialDetails.clientmgrname)

      if (tmpselctedarray.length > 0) {
        this.editedItem.officialDetails.clientmgremail = tmpselctedarray[0].email
        this.editedItem.officialDetails.clientmgrid = tmpselctedarray[0].id
      } else {
        this.assignclinetmanagerdetailsnull()
      }
    },

    selectedreviewersemails() {
      var tmpselctedarray = this.reviewers.filter((obj) => obj.email == this.editedItem.officialDetails.clientmgremail)
      if (tmpselctedarray.length > 0) {
        this.editedItem.officialDetails.clientmgrname = tmpselctedarray[0].name
        this.editedItem.officialDetails.clientmgrid = tmpselctedarray[0].id
      } else {
        this.assignclinetmanagerdetailsnull()
      }
    },
    async getmgrdetails() {
      this.managers = []
      for (var i = 0; i < this.emps.length; ++i) {
        this.managers.push({
          name: this.emps[i].name,
          email: this.emps[i].email,
          id: this.emps[i].uid,
        })
      }
      this.mgrnames = this.managers.map((item) => {
        return item.name
      })
      this.mgremails = this.managers.map((item) => {
        return item.email
      })
      ;(this.reviewers = []),
        reviewersRef
          .doc(this.domain)
          .get()
          .then((doc) => {
            if (doc.exists) {
              this.reviewers = doc.data().reviewers
              this.reviewersnames = this.reviewers.map((item) => {
                return item.name
              })
              this.reviewersemails = this.reviewers.map((item) => {
                return item.email
              })
            }
          })
    },
    async initialize() {
      this.emps = []
      var ret = await this.loadInitialData(empRef, this.domain, this.emps)
      if (ret == true) {
        this.notloaded = false
        this.getmgrdetails()
      } else {
        this.alertflag = true
        this.alertmsg = ret
      }
    },
    save(date) {
      this.$refs.menu.save(date)
    },

    async empleavedateailsupdate(date) {
      console.log(
        "inside empleavedateailsupdate",
        date,
        this.editedItem.officialDetails.email,
        this.editedItem.officialDetails.empid
      )
      var thisyearleave = 0
      var queryresult = await configgenRef
        .where("domain", "==", this.domain)
        .where("description", "==", "Per Month Leave")
        .get()
      var numberofleavespermonth = parseInt(queryresult.docs[0].data().value)
      console.log("numberofleavespermonth", numberofleavespermonth)
      var empdoe = date
      var currentdate = new Date().toISOString().substr(0, 10)
      var year = currentdate.substring(0, 4)

      //var empdoe_year = empdoe.substring(0, 4)
      var empdoe_month = parseInt(empdoe.substring(5, 7))
      var empdoe_date = empdoe.substring(8, 10)
      var remainingmonth = 12 - empdoe_month
      thisyearleave = numberofleavespermonth * remainingmonth
      if (empdoe_date < 5) thisyearleave += 2
      else if (empdoe_date < 12) thisyearleave += 1.5
      else if (empdoe_date < 15) thisyearleave += 1
      else if (empdoe_date < 25) thisyearleave += 0.5
      else if (empdoe_date < 30) thisyearleave += 0

      queryresult = await leavesummariesRef
        .where("domain", "==", this.domain)
        .where("email", "==", this.editedItem.officialDetails.email)
        .where("year", "==", year)
        .get()
      console.log("queryresult", queryresult.docs.length, year, this.domain, this.editedItem.officialDetails.email)
      console.log("remainingmonth", remainingmonth, thisyearleave)
      if (queryresult.docs.length == 0) {
        console.log("No leave is genarated ")
      } else {
        var uid = queryresult.docs[0].id
        try {
          var newcreditedbalance = queryresult.docs[0].data().creditedbalance - thisyearleave
          leavesummariesRef.doc(uid).update({
            closingbalance:
              newcreditedbalance + queryresult.docs[0].data().openingbalance - queryresult.docs[0].data().leavetaken,
            creditedbalance: newcreditedbalance,
            status: "Resigned",
          })
        } catch (error) {
          this.alertflag = true
          this.alertmsg = "Error while update the leavesumary"
          console.log("Eorr while udpate leavesumary", error)
        }
      }
    },
    editItem(item) {
      if (this.Title == "All Details") {
        this.editedIndex = this.emps.indexOf(item)
        this.editedItem = Object.assign({}, item)
        var tmpempvalues
        tmpempvalues = {
          EmpID: this.editedItem.empid,
          domain: this.domain,
          email: this.editedItem.email,
          name: this.editedItem.name,
          mgrname: this.editedItem.mgrname,
          mgremail: this.editedItem.mgremail,
          role: this.roles.role,
        }
        console.log("tmpempvalues", tmpempvalues)
        this.$router.push({ name: "employee", params: { roles: tmpempvalues } })
      } else {
        this.editedIndex = this.emps.indexOf(item)
        this.editedItem = JSON.parse(JSON.stringify(item)) // to assign nested objects have used JSON.parse(JSON.stringify())
        this.dialog = true
      }
    },
    async deleteItem(item) {
      const deletemsg = "Are you sure you want to delete this asset?" + item.officialDetails.empid
      var Ret = await this.commonDeleteItem(item, this.emps, empRef, deletemsg)
      if (Ret == true) {
        this.snackbar = true
        this.snackbartext = "The emp record has been deleted successfully"
      } else {
        this.alertflag = true
        this.alertmsg = "Error while delete" + Ret
      }
    },
    close() {
      this.dialog = false
      this.spinner = false
      this.$nextTick(() => {
        this.editedItem = JSON.parse(JSON.stringify(this.defaultItem))
        this.editedIndex = -1
      })
    },

    async saveitem() {
      this.spinner = true
      if (this.editedIndex > -1) {
        Object.assign(this.emps[this.editedIndex], this.editedItem)
        console.log("inside saveite update", this.editedItem)
        try {
          this.editedItem.domain = this.domain
          await empRef.doc(this.editedItem.uid).set({
            ...this.editedItem,
          })
          // alert("updated successfully")
          if (this.editedItem.officialDetails.empDOE) {
            await this.empleavedateailsupdate(this.editedItem.officialDetails.empDOE)
          }
          this.snackbar = true
          this.snackbartext = "Details Updated Successfully"
          this.close()
        } catch (error) {
          this.spinner = false
          console.log("Error while update :", error)
          this.alertflag = true
          this.alertmsg = "Error while update :" + error
        }
      } // end of if
    },
    addAttachment(options, fileDetails) {
      console.log("inside addAttachmentt options", options)
      this.editedItem.documentDetails.images.push({ ...fileDetails })
    },
    removeAttachment(options, fileDetails) {
      console.log("inside removeAttachment options", options)
      let files = this.editedItem.documentDetails.images.filter((v) => v.filename !== fileDetails.filename)
      this.editedItem.documentDetails.images = [...files]
    },
  }, //enf of methods
}
</script>
